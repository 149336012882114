import { Controller } from 'stimulus';
import { spreeApiController } from "../packs/scripts/SpreeApiController";
import { notyController } from "../packs/scripts/NotyController";
import {accounting} from "accounting";

export default class extends Controller {
  static targets = ['applyCouponCode', 'removeCouponCode', 'couponForm', 'couponCode', 'errorField', 'errorFieldButtons']

  connect(){
    if (this.hasRemoveCouponCodeTarget) this.hideCouponCodeForm();
  }

  async applyCouponCodeWithCheckout(event) {
    await this.ensureOrderToken();

    const headers = spreeApiController.prepareHeaders();
    const couponCode = this.couponCodeTarget.value.toUpperCase();
    const body = {
      "coupon_code": couponCode
    }

    const source = await fetch(`/api/v2/storefront/cart/apply_coupon_code`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(body)
    });

    const response = await this.handleApplyCouponResponse(source, couponCode);

    if (response !== 200) return;

    const navigationButton = document.getElementById('navigationSubmitButton');
    navigationButton.form.submit();
  }


  async applyCouponCode(event) {
    if (event.keyCode !== 13 && event.keyCode !== undefined) return ;

    await this.ensureOrderToken();

    const headers = spreeApiController.prepareHeaders();
    const couponCode = this.couponCodeTarget.value.toUpperCase();
    const body = {
      "coupon_code": couponCode
    }

    const source = await fetch(`/api/v2/storefront/cart/apply_coupon_code`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(body)
    });

    await this.handleApplyCouponResponse(source, couponCode);

    return source;
  }

  async handleApplyCouponResponse(source, couponCode) {
    const json = await source.json();
    const errorElement = this.couponCodeTarget.parentElement.querySelector('.formError');
    const errorElementRegistration = this.errorFieldTarget;
    const errorElementRegistrationButtons = this.errorFieldButtonsTarget;

    if (source.status === 422) {
      if (json.errors.no_user_specified) {
        this.hideFormErrorMessage(errorElement);
        this.showFormErrorMessage(errorElementRegistration, json.errors.no_user_specified[0]);
        errorElementRegistrationButtons.style.display = 'flex';
      } else {
        this.hideFormErrorMessage(errorElementRegistration);
        this.hideFormErrorMessage(errorElementRegistrationButtons);
        this.showFormErrorMessage(errorElement, json.errors.base[0]);
      }
      return source.status;
    }
    if (source.status !== 200) return source.status;

    const orderPromotion = this.findAppliedOrderPromotion(json, couponCode);
    if (orderPromotion === undefined) return;

    this.appendAppliedCoupon(orderPromotion);
    this.hideCouponCodeForm();
    this.updateOrderTotal(json);
    this.hideFormErrorMessage(errorElement);
    this.hideFormErrorMessage(errorElementRegistration);
    this.hideFormErrorMessage(errorElementRegistrationButtons);
    this.clearCouponCodeField();

    notyController.show('success', this.applySuccessMessage());

    return source.status;
  }

  findAppliedOrderPromotion(json, couponCode) {
    const appliedOrderPromotion = json.data.attributes.order_promotions.filter(function(orderPromotion) {
      return orderPromotion.attributes.code == couponCode
    })[0];

    return appliedOrderPromotion?.attributes;
  }

  appendAppliedCoupon(orderPromotion){
    const coupons = document.querySelector('#applied-coupons');

    const newCoupon = document.createElement('div');

    newCoupon.id = '';
    newCoupon.classList = '';
    newCoupon.innerHTML = this.couponTemplate(orderPromotion);

    coupons.appendChild(newCoupon);
  }

  hideCouponCodeForm() {
    this.couponFormTarget.style.display = "none";
  }

  applySuccessMessage() {
    return this.couponCodeTarget.dataset.applySuccessMessage;
  }

  async removeCouponCode() {
    await this.ensureOrderToken();
    const headers = spreeApiController.prepareHeaders();

    const couponCode = this.removeCouponCodeTarget.dataset.couponCode;

    const source = await fetch(`/api/v2/storefront/cart/remove_coupon_code/${couponCode}`, {
      method: 'DELETE',
      headers: headers
    });

    await this.handleRemoveCouponResponse(source);

    return source;
  }

  async handleRemoveCouponResponse(source) {
    const json = await source.json();
    switch (source.status) {
      case 422:
        break;
      case 404:
        break;
      case 500:
        break;
      case 200:
        notyController.show('success', this.removeSuccessMessage());

        this.removeCoupon();
        this.showCouponCodeForm();
        this.updateOrderTotal(json);

        break;
    }
  }

  showFormErrorMessage(errorElement, message) {
    errorElement.innerText = message;
    errorElement.style.display = "block";
  }

  hideFormErrorMessage(errorElement) {
    errorElement.style.display = 'none';
  }

  clearCouponCodeField() {
    this.couponCodeTarget.value = '';
  }

  removeSuccessMessage() {
    return this.couponCodeTarget.dataset.removeSuccessMessage;
  }

  removeCoupon() {
    const coupon = this.removeCouponCodeTarget.parentElement.parentElement.parentElement;
    coupon.remove();
  }

  showCouponCodeForm() {
    this.couponFormTarget.style.display = "block";
  }

  couponTemplate(orderPromotion) {
    return `<span class="coupon-label">
              Kupon
              <strong>
                ${orderPromotion.display_amount}
                <span class="u-color-alphaBase">
                  ${orderPromotion.code}
                  <button type="button"
                          class="icon  delivery-cart__total-price__btn-remove-cupon"
                          data-order-coupons-target="removeCouponCode"
                          data-coupon-code="${orderPromotion.code}"
                          data-action="click->order-coupons#removeCouponCode">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="1">
                      <rect y="1.68555" width="2.38376" height="14.3026" rx="1.19188" transform="rotate(-45 0 1.68555)" fill="#C22C2C" fill-opacity="0.5"/>
                      <rect x="10.1133" width="2.38376" height="14.3026" rx="1.19188" transform="rotate(45 10.1133 0)" fill="#C22C2C" fill-opacity="0.5"/>
                      </g>
                    </svg>
                  </button>
                </span>
              </strong>  
            </span>`
  }

  updateOrderTotal(json) {
    const navBarCart = document.getElementById('js-navbar-cart-button-display-total');
    if (navBarCart) {
      navBarCart.innerHTML = json.meta.price_total;
    }

    const orderTotal = document.getElementById('summary-order-total-sidebar');
    if (orderTotal) {
      orderTotal.innerHTML = json.meta.price_total;
    }
  }

  async ensureOrderToken() {
    if (spreeApiController.orderToken.length === 0) {
      const source = await fetch(`/ensure_cart`, {method: 'POST'});
      const data = await source.json();

      spreeApiController.orderToken = data.token
    }
  }
}
