import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["selectTrigger", "selectOptions", "selectWrapper"];

  connect() {
    console.log('info CONNECT');
    this.selectTriggerTarget.addEventListener("click", this.toggleDropdown.bind(this));
    this.selectOptionsTargets.forEach((option) => {
      option.addEventListener("click", this.selectOption.bind(this));
    });
    document.addEventListener("click", this.closeDropdownOnOutsideClick.bind(this));
  }

  toggleDropdown() {
    this.selectWrapperTarget.classList.toggle("open");
  }

  selectOption(event) {
    console.log('selektirali smo nesto !!!')
    const option = event.target;

    const selectedValue = option.getAttribute("data-value");
    this.selectTriggerTarget.querySelector("span").textContent = option.textContent;
    this.selectWrapperTarget.classList.remove("open");
    this.selectOptionsTargets.forEach((opt) => opt.classList.remove("selected"));
    option.classList.add("selected");

    const form = option.closest('.new-js-sort-by-form');
    const value = selectedValue;
    let url = form.action;

    if (url.match(/\?/)) {
      // Regular expression to match the sort_by parameter and its value
      // Replace the value of sort_by using the regex
      if (url.match(/sort_by=/)) {
        let regex = /([?&]sort_by=)([^&]*)/;
        url = url.replace(regex, `$1${value}`);
      } else {
        url += '&sort_by=' + value;
      }
    } else {
      url += '?sort_by=' + value;
    }

    // Output the updated URL
    console.log(url);

    window.location = url;
  }

  closeDropdownOnOutsideClick(event) {
    if (!this.selectWrapperTarget.contains(event.target)) {
      this.selectWrapperTarget.classList.remove("open");
    }
  }
}
