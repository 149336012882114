import { Controller } from "stimulus";
import { spreeApiController } from "../packs/scripts/SpreeApiController";
import { ErrorCodes } from "../packs/scripts/errorCodes";

export default class extends Controller {
  static targets = [
    "locationSuggest",
    "output",
    "addressOutput",
    "newAddress",
    "locationCoordinates",
    "selectAddressDiv",
    "placeholderImage",
  ];
  static addressDetails;

  static values = { showIf: String };

  async connect() {
    await this.updateModal();
  }

  async updateModal() {
    const response = await this.fetchUserAddresses();

    if (response.selected_address_id) {
      const address = response.addresses.find(
        ({ id }) => id == response.selected_address_id
      );
      if (address) {
        Controller.addressDetails = Object.assign(
          address.attributes.address.attributes,
          { id: response.selected_address_id }
        );
        this.populateAddressLocation();
      }
    }

    this.populateAddressOutputTarget(
      response.addresses,
      response.selected_address_id
    );
    this.populateOutputTarget(response.stock_locations);
  }

  async toggle() {
    if (this.addressOutputTarget.value != "newAddress") {
      this.newAddressTarget.style.display = "none";
      await this.selectAddress(this.addressOutputTarget.value);
      await this.updateModal();
    } else if (this.addressOutputTarget.value == "newAddress") {
      const shipId = document.getElementById("order_ship_address_id");
      const userId = document.getElementById("order_user_id");
      if (shipId != undefined && userId != undefined && userId.value != "") {
        shipId.value = "";
      }

      this.newAddressTarget.style.display = "block";
      Controller.addressDetails = {};
      this.populateOutputTarget([]);
    }
  }

  async selectAddress(addressId) {
    await spreeApiController.ensureTokens();
    const headers = spreeApiController.prepareHeaders();
    const url = `/api/v2/storefront/checkout/update_shipping_address`;
    const body = {
      address_id: addressId,
    };

    const source = await fetch(url, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    });
  }

  async selectedPlaceId(e) {
    e.preventDefault();
    const response = await this.fetchPlaceDetails();
    if (response == undefined) {
      return;
    }
    Controller.addressDetails = response.address_details;

    this.populateOutputTarget(response.stock_locations);
  }

  async fetchPlaceDetails() {
    const placeId = this.locationSuggestTarget.getAttribute("data-place_id");
    const url = this.locationSuggestTarget.getAttribute(
      "data-find_nearest-url"
    );

    if (placeId == null) {
      return;
    }
    const source = await fetch(`${url}?place_id=${placeId}&limit=0`);
    const data = await source.json();

    return this.parsePlaceDetailsData(data);
  }

  async fetchUserAddresses() {
    await spreeApiController.ensureTokens();
    const headers = spreeApiController.prepareHeaders();
    const url = `/api/v2/storefront/checkout/shipping_addresses`;

    const source = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (source.status === 200) {
      const data = await source.json();
      return this.parseUserAddressesData(data);
    }

    return {
      selected_address_id: null,
      addresses: [],
      stock_locations: [],
    };
  }

  parseUserAddressesData(data) {
    const attributes = data.data.attributes;
    return {
      selected_address_id: attributes.selected_address_id,
      addresses: attributes.addresses,
      stock_locations: attributes.stock_locations,
    };
  }

  populateAddressOutputTarget(addresses, selectedAddressId) {
    const addressesHtml = this.createAddressesHtml(
      addresses,
      selectedAddressId
    );
    this.addressOutputTarget.innerHTML = addressesHtml.innerHTML;

    if (!addresses.length) {
      this.addressOutputTarget.value = "newAddress";
      this.selectAddressDivTarget.style.display = "none";
    }
  }

  populateAddressLocation() {
    // this.locationCoordinatesTarget.innerHTML += ` <span className="js-marker"
    //                         data-lat="${Controller.addressDetails.latitude}"
    //                         data-lng="${Controller.addressDetails.longitude}"
    //                         data-title="Adresa"
    //                         data-address="${Controller.addressDetails.street_name, Controller.addressDetails.house_number}"></span>`;
  }

  parsePlaceDetailsData(data) {
    return {
      address_details: data.address_details.data.attributes,
      stock_locations: data.stock_locations.data,
    };
  }

  populateOutputTarget(response) {
    const stockLocationHtml = this.createStockLocationHtml(response);

    this.outputTarget.innerHTML = stockLocationHtml.innerHTML;

    if (this.hasPlaceholderImageTarget) {
      if (response.length > 0) {
        this.placeholderImageTarget.style.display = "none";
      } else this.placeholderImageTarget.style.display = "flex";
    }
  }

  createAddressesHtml(addresses, selectedAddressId) {
    // todo: render HTML partial instead
    const addressesHtml = document.createElement("div");

    addresses.forEach(function (value) {
      const id = value.id;
      const name = `${value.attributes.firstname} ${value.attributes.lastname}`;
      const address = value.attributes.address.attributes;
      const isSelected = id == selectedAddressId ? "selected" : "";
      addressesHtml.innerHTML += `<option value="${id}" ${isSelected}>
                <strong> ${name} </strong>  ${address.street_name} ${address.house_number}, ${address.city}
                </option>`;
    });

    addressesHtml.innerHTML += `<option value="newAddress"> Unesite adresu </option>`;

    return addressesHtml;
  }

  createStockLocationHtml(response) {
    const stockLocationHtml = document.createElement("div");
    if (response.length === 0) {
      return stockLocationHtml;
    }
    stockLocationHtml.innerHTML += ` <p
      class="t-h6 mt-s"
      style="text-transform: unset; color: #333333; letter-spacing: unset;"
    >
      Odaberi ljekarnu:
    </p>`;

    var index = 0;
    response.forEach(function (value) {
      const id = value.id;
      const name = value.attributes.name;
      const address = value.attributes.address.attributes;
      var displayStyleCss = "display: block;";
      if (index > 2) {
        displayStyleCss = "display: none;";
      }

      index += 1;
      stockLocationHtml.innerHTML += `<div class="col w-full mt-xs"
                  id="stock_location_element_${id}"
                  data-action="click->order-location-modal#hideUnselectedStockLocation"
                  style="${displayStyleCss}">
                <div class="e-radio e-radio--medium">
                  <div class="e-radio__item js-pristineParent" style="display: flex; flex-direction: row;">
                    <label class="e-radio__radioItemRedesign" for="stock_location_${id}">
                      <input
                        type="radio"
                        id="stock_location_${id}"
                        name="stockLocation"
                        value="${id}"
                        data-street-name="${address.street_name}"
                        data-house-number="${address.house_number}"
                        data-city="${address.city}"
                      />
                      <div class="e-radio__icon e-radio__radioIconRedesign"></div>
                      <div class="e-radio__radioItemTextRedesign">
                        <span class="t-h5" style="color: #333333;">${name}</span>
                        <strong class="t-h6" style="font-size: 12px;"> ${address.street_name} ${address.house_number}, ${address.city}</strong>
                      </div>
                    </label>
                  </div>
                </div>`;
    });

    stockLocationHtml.innerHTML += `<button type="button" class="e-btn e-btn--alpha mt-xs"
                data-action="click->order-location-modal#storeStockLocation">
                <span class="e-btn__text">Nastavi kupovati</span>
              </button>`;

    return stockLocationHtml;
  }

  hideUnselectedStockLocation(event) {
    if (event.target.value === undefined) return;

    const stockLocations = document.querySelectorAll(
      `[id^="stock_location_element_"]`
    );

    // Only first selected stock location
    const stockLocationId = event.target.value;
    // const selectedStockLocation = stockLocations[0];
    const selectedStockLocationId = "stock_location_element_" + stockLocationId;

    for (const sl of stockLocations) {
      sl.style.display = sl.id !== selectedStockLocationId ? "none" : "block";
    }
  }

  async storeStockLocation() {
    const url = this.outputTarget.getAttribute("data-url");
    const radioBtns = document.querySelectorAll('input[name="stockLocation"]');

    let selectedValue;
    let selectedStockLocation;

    for (const rb of radioBtns) {
      if (rb.checked) {
        selectedStockLocation = rb;
        selectedValue = rb.value;
        break;
      }
    }

    if (selectedValue == undefined) {
      return;
    }

    await this.ensureOrderToken();

    if (!Controller.addressDetails) {
      Controller.addressDetails = this.getSelectedStockLocationAddress(
        selectedStockLocation
      );
    }

    const headers = spreeApiController.prepareHeaders();
    const body = {
      order: { stock_location_id: selectedValue },
      address_details: Controller.addressDetails,
    };

    const source = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });
    switch (source.status) {
      case 422:
        const error = await source.json();
        if (error.errors.code == ErrorCodes.invalidStockLocation) {
          location.reload();
          // console.log('something went wrong')
          // todo: add notification that something went wrong, try again?
        }
        break;
      case 500:
        // console.log('error 500')
        break;
      case 200:
        const variantId = this.outputTarget.dataset.variantId;
        const btnSelect = document.querySelector(
          `[data-variant-id="${variantId}"][data-button-role=add_to_cart]`
        );
        const btnCart = document.querySelector(`#navigationSubmitButton`);

        if (btnSelect) {
          btnSelect.click();
          MicroModal.close();
        }
        if (btnCart) location.reload();
    }
  }

  async ensureOrderToken() {
    if (spreeApiController.orderToken.length === 0) {
      const source = await fetch(`/ensure_cart`, { method: "POST" });
      const data = await source.json();

      spreeApiController.orderToken = data.token;
    }
  }

  getSelectedStockLocationAddress(selectedStockLocation) {
    if (!selectedStockLocation) return {};

    return {
      street_name: selectedStockLocation.dataset.streetName,
      house_number: selectedStockLocation.dataset.houseNumber,
      city: selectedStockLocation.dataset.city,
    };
  }
}
