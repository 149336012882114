document.addEventListener("DOMContentLoaded", () => {
  const SELECTORS = {
    categoryToggleButton: ".product-page-redesign__category-btn__open",
    productPageRedesign: ".product-page-redesign",
    productPageRedesignCategory: ".product-page-redesign__category",
    categoryButtons: ".product-page-redesign__category-btn",
    showCategory: ".product-page-redesign__category-btn__open__show-category",
    hideCategory: ".product-page-redesign__category-btn__open__hide-category",
  };

  const categoryToggleButton = document.querySelector(
    SELECTORS.categoryToggleButton
  );
  const productPageRedesign = document.querySelector(
    SELECTORS.productPageRedesign
  );
  const productPageRedesignCategory = document.querySelector(
    SELECTORS.productPageRedesignCategory
  );

  if (!productPageRedesignCategory || !categoryToggleButton) return;

  const buttons = productPageRedesignCategory.querySelectorAll(
    SELECTORS.categoryButtons
  );

  const showCategoryElement = categoryToggleButton.querySelector(
    SELECTORS.showCategory
  );
  const hideCategoryElement = categoryToggleButton.querySelector(
    SELECTORS.hideCategory
  );

  if (!showCategoryElement || !hideCategoryElement) {
    console.error("Show or hide category elements are missing in the button.");
    return;
  }

  // Check if categories fit in a single row
  const categoriesFitInSingleRow = () => {
    if (buttons.length <= 1) return true;

    const firstRowTop = buttons[0].offsetTop;
    return !Array.from(buttons).some(
      (button) => button.offsetTop > firstRowTop
    );
  };

  if (categoriesFitInSingleRow()) {
    categoryToggleButton.style.display = "none";
    return;
  }

  const toggleCategoryView = () => {
    const isExpanded = categoryToggleButton.dataset.isExpanded === "true";
    var categoryList = $("#categoryList");
    if (!isExpanded) {
      categoryList.find("li:last").after($("#toggleCategoryList"));
    } else {
      var categoryListWidth = categoryList.innerWidth();
      var initialWidth = $("#toggleCategoryList").innerWidth();

      categoryList.find("li").each(function () {
        initialWidth += $(this).innerWidth() + 12;
        if (initialWidth > categoryListWidth) {
          $(this).before($("#toggleCategoryList"));
          return false;
        }
      });
    }

    // Toggle styles based on the current state
    productPageRedesign.style.height = isExpanded ? "40px" : "100%";
    productPageRedesign.style.overflow = isExpanded ? "hidden" : "visible";

    // Toggle visibility of text elements
    showCategoryElement.style.display = isExpanded ? "inline" : "none";
    hideCategoryElement.style.display = isExpanded ? "none" : "inline";

    // Update `data-is-expanded`
    categoryToggleButton.dataset.isExpanded = !isExpanded;
  };

  // Initially show the correct text
  showCategoryElement.style.display = "inline";
  hideCategoryElement.style.display = "none";

  categoryToggleButton.addEventListener("click", toggleCategoryView);
});

$(document).ready(function () {
  var categoryList = $("#categoryList");
  var toggleButton = $("#toggleCategoryList");
  var categoryListWidth = categoryList.innerWidth();
  var initialWidth = toggleButton.innerWidth();

  categoryList.find("li").each(function () {
    initialWidth += $(this).innerWidth() + 12;
    if (initialWidth > categoryListWidth) {
      $(this).before(toggleButton);
      return false;
    }
  });
});
