import { Controller } from 'stimulus';
import { accounting } from 'accounting';

export default class extends Controller {
  static targets = [ 'shipping', 'shippingTotal', 'orderTotal', 'shippingCost', 'storePickupCost',
                     'shippingDisplay' ];

  initialize() {
    if (!this.hasShippingTotalTarget) return;

    this.changeLabels();

    if (parseFloat(this.shippingTotalTarget.dataset.cost) > 0) {
      this.shippingDisplayTarget.style.display = 'block';
    }

    this.navBarCart = document.getElementById('js-navbar-cart-button-display-total');

    this.shipmentTotal = this.shippingTotalTarget.dataset;

    if (this.hasShippingCostTarget && this.hasStorePickupCostTarget) {
      this.shippingCosts = {
        delivery: this.shippingCostTarget.dataset.cost,
        store: this.storePickupCostTarget.dataset.cost,
      };
    }

    this.formatOptions = {
      symbol: this.shipmentTotal.currency,
      decimal: this.shipmentTotal.decimalMark,
      thousand: this.shipmentTotal.thousandsSeparator,
      precision: this.shipmentTotal.precision,
      format: '%v %s',
    };

    if (this.hasShippingCostTarget && this.shippingCostTarget.dataset.otcRedirect) {
      this.recalculatePrice({
        target: { id: this.shippingCostTarget.id },
      });
    }

    const radioCheckedValue = document.querySelector('.js-radioTabs__trigger:checked');
    if (radioCheckedValue === undefined) return;
  }

  selectShippingMethod = (e) => {
    if (e.target.id === 'delivery' && this.shippingCostTarget.dataset.otcPopup === 'true') return;

    this.recalculatePrice(e);
    this.changeLabels(e);
  }

  changeLabels = (e) => {
    const addressElement = document.getElementById('js-address-missing-data-delivery');
    const storeElement   = document.getElementById('js-order-delivery-method-store-title');
    if (addressElement === null && storeElement === null) return;

    if (e !== undefined && e.target.id === 'delivery') {
      addressElement.innerHTML = addressElement.dataset.deliveryAddressTitle;
      storeElement.innerHTML   = storeElement.dataset.deliveryAddressTitle;
    } else {
      addressElement.innerHTML = addressElement.dataset.storeAddressTitle;
      storeElement.innerHTML   = storeElement.dataset.storeAddressTitle;
    }
  }

  recalculatePrice = (e) => {
    this.readjustSummarySection(
      this.parseCurrencyToFloat(this.orderTotalTarget.innerHTML),
      this.parseCurrencyToFloat(this.shippingCosts[e.target.id]),
      this.parseCurrencyToFloat(this.shippingTotalTarget.innerHTML),
    );
  }

  parseCurrencyToFloat = (input) => {
    return accounting.unformat(input, this.formatOptions.decimal);
  }

  readjustSummarySection = (orderTotal, newShipmentTotal, oldShipmentTotal) => {
    var newOrderTotal = orderTotal + (newShipmentTotal - oldShipmentTotal);
    var displayTotal = accounting.formatMoney(accounting.toFixed(newOrderTotal, 10),
                                              this.formatOptions);

    var shippingTitle = this.shippingTotalTarget.dataset.titleLabel;
    this.shippingTotalTarget.innerHTML = shippingTitle + `<strong>` + accounting.formatMoney(newShipmentTotal, this.formatOptions); + `</strong>`
    
    this.hideOrShowShippingTotal(newShipmentTotal);

    if (this.navBarCart) {
      this.navBarCart.innerHTML = displayTotal;
    }

    return this.orderTotalTarget.innerHTML = displayTotal;
  }

  hideOrShowShippingTotal = (total) => {
    if (total > 0) return this.shippingDisplayTarget.style.display = 'block';

    this.shippingDisplayTarget.style.display = 'none';
  }
}
