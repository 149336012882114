document.addEventListener("DOMContentLoaded", function () {
  // Main container for event delegation
  const sidebarNav = document.querySelector(".new-side-bar-nav");

  // Event listener to handle both open and close button clicks
  sidebarNav.addEventListener("click", function (event) {
    // Identify if the clicked element is an open or close button
    const button = event.target.closest(
      ".new-side-bar-nav__open, .new-side-bar-nav__close"
    );
    if (!button) return; // Exit if click was outside of the target buttons

    event.preventDefault();

    // Fetch the target section's identifier from the data-target attribute
    const target = button.getAttribute("data-target");
    // Find the closest section that matches the target attribute
    const targetSection = button.closest(`.new-side-bar-nav__${target}`);

    if (targetSection) {
      const isOpenButton = button.classList.contains("new-side-bar-nav__open");

      // Toggle button visibility: hide clicked button, show the opposite button
      button.classList.add("d-none");
      const oppositeButtonClass = isOpenButton
        ? ".new-side-bar-nav__close"
        : ".new-side-bar-nav__open";
      targetSection
        .querySelector(`${oppositeButtonClass}[data-target='${target}']`)
        .classList.remove("d-none");

      // Set height for the section based on whether it's opening or closing
      targetSection.style.transition = "max-height 0.3s ease-in-out"; // Set to 0.3s
      if (target === "price-range") {
        targetSection.style.height = isOpenButton ? "116px" : "45px"; // Specific heights for price range
      } else if (target === "filters-new") {
        targetSection.style.maxHeight = isOpenButton ? "375px" : "55px"; // Specific heights for filters section
      }
    }
  });

  // Handle sidebar functionality for small devices
  const elements = {
    sidebarBtn: document.querySelector(".target-filters-mobile-btn"),
    sidebarSection: document.querySelector(".target-new-side-bar"),
    closeBtn: document.querySelector(".new-close-btn"),
  };

  // Styles object for sidebar appearance
  const sidebarStyles = {
    display: "block",
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "100%",
    backgroundColor: "#fff",
    zIndex: "9999",
    overflow: "hidden",
    overflowY: "auto",
    padding: "20px 15px",
  };

  // Function to apply styles to an element if it exists
  const applyStyles = (element, styles) => {
    if (element) {
      Object.assign(element.style, styles);
    }
  };

  // Add event listeners if elements exist
  elements.sidebarBtn?.addEventListener("click", (e) => {
    e.preventDefault();
    applyStyles(elements.sidebarSection, sidebarStyles);
    elements.sidebarSection.classList.add("open");
    elements.sidebarSection.classList.remove("close");
  });

  elements.closeBtn?.addEventListener("click", () => {
    if (elements.sidebarSection) {
      elements.sidebarSection.classList.add("close");
      setTimeout(() => {
        elements.sidebarSection.style.display = "none";
      }, 300);
    }
  });

  // Handle overflow behavior for the filter list
  const filterLists = document.querySelectorAll(".c-filterList__list");

  filterLists.forEach((filterList) => {
    const filterItems = filterList.querySelectorAll(".c-filterList__li");

    if (filterItems.length > 10) {
      filterList.style.overflowY = "scroll";
    } else {
      filterList.style.overflowY = "hidden";
    }
  });
});
