import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import 'array-from-polyfill';

class FilterController {
  constructor() {
    this.target
    this.btnOpen
    this.btnClose
    this.btnReset
    this.targetScroll
    this.form
  }
  init() {
    this.siteBody = document.querySelector('body')
    this.target = document.querySelector('.js-filter-wrapper-mobile')
    this.btnOpen = document.querySelector('.js-open-filter')
    this.btnClose = document.querySelector('.js-close-filter')
    this.targetScroll = document.querySelector('.js-target-scroll')
    this.btnReset = document.querySelector('.js-reset-filter')
    this.form = document.querySelector('.js-filter-form')

    if (this.target != null) {
      this.events();
    }
  }

  events() {
    if (this.btnOpen) {
      this.btnOpen.addEventListener('click', () => {
        this.open()
      });
    }
    if (this.btnClose) {
      this.btnClose.addEventListener('click', () => {
        this.close()
      });
    }
    if (this.btnReset) {
      this.btnReset.addEventListener('click', () => {
        this.reset()
      });
    }
  }

  toggle() {
    this.siteBody.classList.contains('state--filterActive') ? this.close() : this.open()
  }

  open() {
    this.siteBody.classList.add('state--filterActive')
    if (this.targetScroll != null) {
      disableBodyScroll(this.targetScroll)
    }
  }

  close() {
    this.siteBody.classList.remove('state--filterActive')
    if (this.targetScroll != null) {
      enableBodyScroll(this.targetScroll)
    }
  }

  reset() {
    this.form.reset();
  }
}

export let filterController = new FilterController();
